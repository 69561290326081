<template>
    <div class="pageMain">
      <div class="flex">
        <!-- 组织项目树 -->
  
        <OriginTree class="tree"  :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>
  
        <!--右边列表  -->
        <div class="form">
          <SearchHead
            @search="search"
            @reset="reset"
           
          
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="人员姓名">
                <el-input
                  v-model="searchForm.operName"
                  size="small"
                  placeholder="人员姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="人员性别">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="searchForm.operSex"
                  placeholder="请选择性别"
                >
                  <el-option
                    v-for="item in sexState"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
        </el-form-item>
              <el-form-item label="手机号码">
                <el-input
                  v-model="searchForm.operMobile"
                  size="small"
                  placeholder="手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input
                  v-model="searchForm.operIdcard"
                  size="small"
                  placeholder="证件号码"
                ></el-input>
              </el-form-item>
              
            </el-form>
          </SearchHead>
          <CustomTables
            :data="tableData"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          
            :total="total"
            title="驾驶人员"
          >
            <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
               
                class="btn"
                >新建</el-button
              >
            </template>
           
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="operName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="operIdcard" label="证件" align="center">
            </el-table-column>
            <el-table-column prop="operSex" label="性别" align="center">
              <template slot-scope="scope">
                  <div v-if="scope.row.operSex==0">男</div>
                  <div v-if="scope.row.operSex==1">女</div>
        </template>
            </el-table-column>
            <el-table-column prop="operMobile" label="电话" align="center">
            </el-table-column>
            <el-table-column prop="startTime" label="驾照颁发日期" align="center">
            </el-table-column>
            <el-table-column prop="endTime" label="驾照结束日期" align="center">
            </el-table-column>
            <el-table-column prop="cardNo" label="驾照编号" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >
              <i class="el-icon-edit"></i>
            </span>
            <span
                @click="deleteItem(scope.row)"
                class="detail delete"
              >
              <i class="el-icon-delete"></i>
            </span>
            </template>
          </el-table-column>
            
          </CustomTables>
        </div>
      </div>
      <driverEdit  :visible.sync="showDialog" @close="colseDialog" :title="title"  @done="doneDialog" :item="item">
    
  </driverEdit>
    </div>
  </template>
  
  <script>
  import OriginTree from "../../../components/common/OriginTree.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import driverEdit  from '../../../components/basic/driverEdit.vue'
  import basic from "../../../api/modules/basic";
  import {sexState} from "../../../config/dataStatus"
  export default {
    components: {
      OriginTree,
      SearchHead,
      CustomTables,
      SearchLeft,
      driverEdit
    },
    name: "statistics",
    data() {
      return {
        searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
        showDialog:false,
        tableData: [
         
        ],
        options: [
          
        ],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        title:'',
        item:{},
        filterText:'',
        sexState:sexState
      };
    },
    mounted() {},
    methods: {
      addFrom() {
        this.showDialog=true
        this.title='新增驾驶员'
        this.item={}
      },
      init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.searchForm.pageNum = 1;
      this.orgaId=val.orgaId;
      this.getList();
    },
    getList() {
      basic.operatorsGetPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      detailItem(item){
          //详情
          this.showDialog=true
        this.title='编辑驾驶员'
        this.item=item
      },
      doneDialog(){
        this.showDialog = false
        this.getList()
      },
      deleteItem(item){
        this.$confirm(`此操作将永久删除该驾驶人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        basic.deleteOperatorDriver( item.operId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
      },
      colseDialog(){
        this.showDialog=false
      },
      handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      this.getList()
    },
    },
  };
  </script>
  
  <style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail{
      color: #008f4d;
  }
  .delete{
    margin-left: 10px;
}
  </style>
  