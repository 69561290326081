<template>
    <custom-dialog
      :visible.sync="visible"
      :title="title"
      @close="colseDialog"
      @submit="submit"
      width="45%"
    >
      <div class="editTitle">驾驶员基本信息</div>
  
      <div class="edit-main">
        <el-form
          :model="editForm"
          label-width="120px"
          :rules="rules"
          ref="editVersionForm"
        >
          <el-row >
            <el-form-item label="司机照片" prop="operFaceUrl">
          <el-upload
              class="avatar-uploader"
              :action="`${uploadUrl}File/upload`"
              :show-file-list="false"
              :on-change="uploadChange"
              :data={type:1}
              :on-success="uploadSuccess2">
            <img
                v-if="editForm.operFaceUrl"
                :src="editForm.operFaceUrl"
                class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
            <el-col :span="12">
              <el-form-item label="姓名：" prop="operName">
                <el-input
                  v-model="editForm.operName"
                  size="small"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件号：" prop="operIdcard">
                <el-input
                  v-model="editForm.operIdcard"
                  size="small"
                  placeholder="请输入证件号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属项目：" prop="projId">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  filterable
                  v-model="editForm.projId"
                  placeholder="请选择所属项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" prop="operSex">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="editForm.operSex"
                  placeholder="请选择性别"
                >
                  <el-option
                    v-for="item in sexState"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="驾照编号：" prop="cardNo">
                <el-input
                  v-model="editForm.cardNo"
                  size="small"
                  placeholder="请输入驾照编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="operMobile">
                <el-input
                  v-model="editForm.operMobile"
                  size="small"
                  placeholder="请输入联系电话"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="颁发日期：" prop="startTime">
                <el-date-picker
                class="date"
                  v-model="editForm.startTime"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择颁发日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用状态：" prop="state">
                <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="editForm.state"
                  placeholder="请选择使用状态"
                >
                  <el-option
                    v-for="item in deviceState"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束日期：" prop="endTime">
                <el-date-picker
                class="date"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="editForm.endTime"
                  type="datetime"
                  placeholder="选择结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件：" prop="staffPic">
          <el-upload
              class="file-uploader"
              :action="`${uploadUrl}File/upload`"
              :data="{type:1}"
              :show-file-list="false"
              :on-success="uploadSuccess">
            <div class="upload-main">
                    <span class="upload-btn">
                      <i class="el-icon-upload"></i>上传附件
                    </span>
              仅支持上传图片、pdf格式
            </div>
            <div class="file-url-main">
              <div v-for="(url,index) in editForm.annexUrl" class="file-url-item" :key="index">
               <div class="file-url">{{url.match('--')? url.split('--')[1]: url}}</div>
                <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
              </div>
            </div>
          </el-upload>
        </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </custom-dialog>
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  import {  phoneReg } from "../../utils/tool";
    import {sexState,deviceState} from "../../config/dataStatus"
    import basic from '../../api/modules/basic'
  
  export default {
    name: "driverEdit",
    components: { CustomDialog },
    props: ["visible", "title",'item'],
    data() {
      let checkMobile = (rule, value, callback) => {
      if (!phoneReg(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
      return {
        editForm: {
          
        },
        rules: {
          operName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
          projId: [
            { required: true, message: "请选择所属项目", trigger: "change" },
          ],
          operIdcard: [{ required: true, message: "请输入证件号", trigger: "blur" }],
          operSex: [{ required: true, message: "请选择性别", trigger: "change" }],
          cardNo: [
            { required: true, message: "请输入驾照编号", trigger: "blur" },
          ],
          operMobile: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { validator: checkMobile, trigger: "blur" },
          ],
          startTime: [{ required: true, message: "请选择颁发日期", trigger: "change" }],
          state: [{ required: true, message: "请选择使用状态", trigger: "change" }],
          endTime: [{ required: true, message: "请选择结束日期", trigger: "change" }],
        },
        uploadUrl: config.uploadUrl,
        equipType: [],
        sexState:sexState,
        deviceState:deviceState,
        projectList:[]
        // equipType: equipType
      };
    },
    watch: {
    item(val) {
      console.log('val',val);
      
      if (val.operId) {
        this.editForm = val;
      }else{
        this.editForm={
          operName:'',
          projId:'',
          operIdcard:'',
          operSex:'',
          cardNo:'',
          operMobile:'',
          startTime:'',
          state:'',
          endTime:''

        }
      }
    },
  },
    mounted(){
      this.getProjectList()
    },
    methods: {
      colseDialog() {
        this.$emit("close");
      },
      getProjectList(){
      basic.GetProjectList().then(res=>{
        console.log('项目列表',res);
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
      submit() {
        this.$refs.editVersionForm.validate((valid) => {
          if (valid) {
            if(this.editForm.operId){
              basic.updateOperatorsDriver(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
            }else{
              basic.addOperatorsDriver(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
            }
         
          }
        });
      },
      uploadSuccess(val){
        if(val.code == 200){
          // 附件信息，未完待续
          this.editForm.annexUrl.push(val.data)
          this.$forceUpdate()
        }
      },
      uploadSuccess2(val){
        if (val.code == 200) {
        this.editForm.operFaceUrl = val.message;
      }
      },
      uploadChange(file) {
      console.log(file,'file');
      // common.fileUpload()
    },
      delImg(index){
        this.editForm.annexUrl.splice(index,1)
        this.$forceUpdate()
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .editTitle {
    border: 1px solid #eeeeee;
    border-bottom: 0px solid  #eeeeee;
    padding: 10px 0 10px 30px;
    border-radius: 6px 6px 0px 0px;
    font-weight: bold;
  }
  .edit-main {
    padding: 20px;
    border: 1px solid #eeeeee;
    border-radius: 0px 0px 6px 6px;
  }

  </style>
  